<template>
    <div class="dropdown-container">
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      <button class="add-button" @click="handleChange">Add</button>
      <select class="custom-select" id="dropdown" v-model="ticker">
        <option disabled value="">Select Symbol</option>
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <input type="text" v-model="quantity" class="qty-input" placeholder="Qty">
    </div>
  </template>
  
  <script>
  export default {
    props: {
      symbols: {
        required: false
      }
    },
    data() {
      return {
        ticker: '', 
        quantity: '',
        options: [
          { value: 'AAPL', text: 'AAPL' },
          { value: 'AMZN', text: 'AMZN' },
          { value: 'COST', text: 'COST' },
          { value: 'CRM', text: 'CRM' },
          { value: 'CRWD', text: 'CRWD' },
          { value: 'DIA', text: 'DIA' },
          { value: 'GOOGL', text: 'GOOGL' },
          { value: 'GS', text: 'GS' },
          { value: 'IWM', text: 'IWM' },
          { value: 'MAR', text: 'MAR' },
          { value: 'MCD', text: 'MCD' },
          { value: 'META', text: 'META' },
          { value: 'MSFT', text: 'MSFT' },
          { value: 'NFLX', text: 'NFLX' },
          { value: 'NVDA', text: 'NVDA' },
          { value: 'QQQ', text: 'QQQ' },
          { value: 'SPY', text: 'SPY' },
          { value: 'TSLA', text: 'TSLA' },
          { value: 'TQQQ', text: 'TQQQ' },
          { value: 'XLK', text: 'XLK' }
        ]
      };
    },

    methods: {
      handleChange() {
        if (! /^[0-9]+$/.test(this.quantity)) {
          this.quantity = '';
          return;
        }

        if (this.symbols && this.symbols.some(item => item.ticker === this.ticker)) {
          this.ticker = '';
          return;
        }
        
        const qty = parseInt(this.quantity);
        this.$emit('change', this.ticker, qty);
        this.ticker = '';
        this.quantity = '';
      }
    }
  };
  </script>
  
  <style>
.dropdown-container {
  font-family: 'Montserrat', sans-serif; /* Use the font that matches your design */
  width: 100%; /* Adjust the width as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-select {
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
  margin-right: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none;    /* Removes default styling on Firefox */
  appearance: none;         /* Removes default styling */
  font-family: 'Montserrat', sans-serif;
}

/* Add custom arrow indicator */
.custom-select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px;
}

/* Remove focus outline for a specific class - remember this could affect accessibility */
.custom-select:focus {
  outline: none;
}

.add-button {
  cursor: pointer;
  padding: 12px 16px;
  margin-right: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;         
  font-family: 'Montserrat', sans-serif;
}

.qty-input {
  width: 40px;
  padding: 12px 16px;
  margin-right: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;        
  font-family: 'Montserrat', sans-serif;
}

  </style>
  