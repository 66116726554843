import Vue from 'vue';
import VueRouter from 'vue-router';
import SummaryPage from './components/Summary/SummaryPage.vue';
import LoginPage from './components/Login/LoginPage.vue';
import AuthComponent from './components/Auth/AuthComponent.vue';
import NotFound from './NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {path: '/', name: 'Home', component: SummaryPage},
  {path: '/login', name: 'Login', component: LoginPage},
  {
    path: '/api/auth',
    name: 'Auth',
    component: AuthComponent,
    props: (route) => ({
      oauth_token: route.query.oauth_token,
      oauth_verifier: route.query.oauth_verifier
    })
  },
  {path: '*', name: 'Not Found', component: NotFound}
];

const router = new VueRouter({routes});

/**
 * Navigation guards to prevent user from accessing wrong pages.
 */
router.beforeEach((to, from, next) => {
  if (router.app.$store) {
    if (to.name === 'Login' && router.app.$store.state.username) {
      next({name: 'Home'}); // Go to Account page if user navigates to Login and are signed in
      return;
    }

    if (to.name === 'Home' && !router.app.$store.state.username) {
      next({name: 'Login'}); // Go to Login page if user navigates to Account and are not signed in
      return;
    }

    if (to.name === 'Not Found') {
      next({name: 'Home'}); // Go to Account page if user navigates to Login and are signed in
      return;
    }
  }

  next();
});

export default router;
