<!-- Page for account sign-in and registration -->
<!-- User should be NOT authenticated in order to see this page -->

<template>
  <main>
    <section>
      <LoginForm />
    </section>
  </main>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {LoginForm}
};
</script>

<style scoped>
section {
  display: flex; 
  justify-content: center; 
  align-items: center;
}
</style>
