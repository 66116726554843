<template>
    <div>
      <h1>OAuth Token: {{ decodeURIComponent(oauth_token) }}</h1>
      <h1>OAuth Verifier: {{ decodeURIComponent(oauth_verifier) }}</h1>
  
      <!-- Display API response data -->
      <div v-if="apiResponse">
        <h2>API Response:</h2>
        <pre>{{ apiResponse }}</pre>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      oauth_token: String,
      oauth_verifier: String
    },
    data() {
      return {
        apiResponse: null
      };
    },
    created() {
      this.makeApiRequest();
    },
    methods: {
      makeApiRequest() {
        const options = {
          method: 'POST',
          body: JSON.stringify({oauth_token: this.oauth_token, oauth_verifier: this.oauth_verifier}),
          headers: {'Content-Type': 'application/json'},
          credentials: 'same-origin' // Sends express-session credentials with request
        }
        fetch('/api/auth', options).then(response => {
            this.apiResponse = response.message;
        }).catch(error => {
            console.error('API request error:', error);
        });
      }
    }
  };
  </script>
  