<template>
  <div class="card">
    <div class="card-header">{{ strat['title'] }}</div>
    <div class="card-desc">{{ strat['desc'] }}</div>
    <div class="card-body">
      <div class="symbols-container">
        <div class="symbol" v-for="(symbol, index) in strat['symbols']" :key="index">
          <label class="switch">
            <input type="checkbox" checked @click="handleSliderClick(symbol['ticker'])">
            <span class="slider round"></span>
          </label>
          <span class="symbol-text">{{ symbol['ticker'] }}</span>
          <input type="text" v-model="symbol['quantity']" class="symbol-input" @input="handleQuantityChange(symbol)">
        </div>
      </div>
      <SymbolDropdown :symbols="strat['symbols']" @change="handleAddSymbol" />
    </div>
  </div>
</template>

<script>
import SymbolDropdown from '../common/SymbolDropdown.vue';

export default {
  name: 'ActiveStrat',
  components: {SymbolDropdown},
  props: {
    strat: {
      required: true
    }
  },

  data() {
    return {
      alerts: {} // Displays any success/error messages
    };
  },

  methods: {
    async handleSliderClick(symbol) {
      await this.disableSymbol(symbol);

      this.$emit('update');
    },

    async handleAddSymbol(ticker, quantity) {
      await this.updateQuantity(ticker, quantity);
      await this.activateSymbol(ticker);

      this.$emit('update');
    },

    async handleQuantityChange(symbol) {
      const name = this.strat.name;
      const ticker = symbol.ticker;
      const quantity = symbol.quantity;

      if (! /^[0-9]+$/.test(quantity)) {
        return;
      }

      await this.updateQuantity(ticker, quantity);

      this.$emit('update');
    },

    async updateQuantity(ticker, quantity) {
      const options = {
        method: 'POST',
        body: JSON.stringify({strategy: this.strat['name'], symbol: ticker, quantity: quantity}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin' // Sends express-session credentials with request
      };
      try {
        const response = await fetch('/api/strats/quantity', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    },

    async activateSymbol(symbol) {
      const options = {
        method: 'POST',
        body: JSON.stringify({strategy: this.strat['name'], symbol: symbol}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin' // Sends express-session credentials with request
      }
      try {
        const response = await fetch('/api/strats/enable', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    },

    async disableSymbol(symbol) {
      const options = {
        method: 'POST',
        body: JSON.stringify({strategy: this.strat['name'], symbol: symbol}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin' // Sends express-session credentials with request
      }
      try {
        const response = await fetch('/api/strats/disable', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    }
  }
};
</script>

<style scoped>
.card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 450px;
  flex-shrink: 0;
}

.card-header {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.card-desc {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.symbols-container {
  display: flex;
  flex-wrap: wrap;
}

.symbol {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.symbol-text {
  margin-right: 0px;
}

  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #76EE59;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.symbol-input {
  width: 40px;
  padding: 12px 16px;
  margin-right: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;        
  font-family: 'Montserrat', sans-serif;
}

</style>
