<template>
  <div class="card">
    <div class="card-header">{{ strat['title'] }}</div>
    <div class="card-desc">{{ strat['desc'] }}</div>
    <div class="card-body">
      <SymbolDropdown @change="handleAddSymbol" />
    </div>
  </div>
</template>

<script>
import SymbolDropdown from '../common/SymbolDropdown.vue';

export default {
  name: 'InactiveStrat',
  components: {SymbolDropdown},
  props: {
    strat: {
      required: true
    }
  },
  data() {
    return {
      alerts: {} // Displays any success/error messages
    };
  },
  methods: {
    async handleAddSymbol(ticker, quantity) {
      await this.updateQuantity(ticker, quantity);
      await this.activateSymbol(ticker);
      
      this.$emit('update');
    },

    async updateQuantity(ticker, quantity) {
      const options = {
        method: 'POST',
        body: JSON.stringify({strategy: this.strat['name'], symbol: ticker, quantity: quantity}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin' // Sends express-session credentials with request
      };
      try {
        const response = await fetch('/api/strats/quantity', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    },

    async activateSymbol(symbol) {
      const options = {
        method: 'POST',
        body: JSON.stringify({strategy: this.strat['name'], symbol: symbol}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin' // Sends express-session credentials with request
      }
      try {
        const response = await fetch('/api/strats/enable', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    }
  }
};
</script>

<style scoped>
.card {
  flex-shrink: 0;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 450px;
}

.card-header {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.card-desc {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

</style>
