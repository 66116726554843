<template>
  <main class="main-container">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <div class="header">
      <h1>Executive Summary</h1>
      <div class="account">
        <ETradeDropdown />
      </div>
    </div>
    <h2>Active Strategies</h2>
    <button class="kill-button" @click="killAll">Kill All</button>
    <div class="cards-container" v-if="actives.length > 0">
      <ActiveStrat v-for="active in actives" @update="refresh"
      :strat="active" />
    </div>
    <div v-else>None</div>
    <h2>Inactive Strategies</h2>
    <div class="cards-container" v-if="inactives.length > 0">
      <InactiveStrat v-for="inactive in inactives" @update="refresh"
        :strat="inactive" />
    </div>
    <div v-else>None</div>
  </main>
</template>

<script>
import ActiveStrat from '@/components/Summary/ActiveStrat.vue';
import InactiveStrat from '@/components/Summary/InactiveStrat.vue';
import ETradeDropdown from '../common/ETradeDropdown.vue';

export default {
  name: 'SummaryPage',
  components: {ActiveStrat, InactiveStrat, ETradeDropdown},

  data() {
    return {
      actives: [],
      // CURR: [['Aroon Osc 14', ['QQQ', 'SPY']], ['DPO Continuous', ['TSLA', 'NVDA']], ['Bol %b', ['NVDA']]]
      // NEW: [{name: 'aroon-osc-14', title: 'Aroon Osc 14', desc: 'lorem ipsum ...', symbols: [{ticker: 'QQQ', quantity: 1}, ...]}, ...]
      /* actives: [{name: 'aroon-osc-14', title: 'Aroon Osc 14', desc: 'lorem ipsum ... lorem ipsum ... lorem ipsum ... lorem ipsum ... lorem ipsum ... lorem ipsum ... lorem ipsum ... lorem ipsum ...', symbols: [{ticker: 'QQQ', quantity: 1},  {ticker: 'SPY', quantity: 2}]},
                {name: 'dpo-cont', title: 'DPO Continuous', desc: 'lorem ipsum ...', symbols: [{ticker: 'TSLA', quantity: 3},  {ticker: 'NVDA', quantity: 4}]}], */
      inactives: [],
      // CURR: ['CCI 10', '3ma 5, 10, 30', 'PMO 35, 20, 10', 'TII', 'Supertrend']
      // NEW: [{name: 'cci-10', title: 'CCI 10', desc: 'lorem ipsum ...'}, ...]
      //inactives: [{name: 'cci-10', title: 'CCI 10', desc: 'lorem ipsum ...'}, {name: 'cci-10', title: 'CCI 10', desc: 'lorem ipsum ...'}],
      message: null
    }
  },

  created() {
    if (!this.$store.state.username) {
      this.$router.push({ name: 'Login' });
    }
    this.refresh();
  },

  methods: {
    async refresh() {
      try {
        await this.getActives();
        this.getInactives();
      } catch (error) {
        console.error('Error in refresh:', error);
      }
    },

    async getActives() {
      return fetch('/api/strats', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(res => {
        this.actives = res;
      })
      .catch(error => {
        console.error('API request error:', error);
        throw error;
      });
    },

    getInactives() {
      fetch('/api/strats/inactives', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(res => {
        this.inactives = res.filter(inactive => !this.actives.map(strat => strat.name).includes(inactive.name));
      })
      .catch(error => {
        console.error('API request error:', error);
        throw error;
      });
    },

    async disableSymbol(strategy, symbol) {
      const options = {
        method: 'POST',
        body: JSON.stringify({strategy: strategy, symbol: symbol}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'same-origin' // Sends express-session credentials with request
      }
      try {
        const response = await fetch('/api/strats/disable', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    },

    async killAll() {
      for (const strat of this.actives) {
        for (const symbol of strat.symbols) {
          await this.disableSymbol(strat.name, symbol.ticker);
        }
      }
      this.refresh();
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cards-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.main-container {
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Montserrat', Arial, sans-serif;
}

.kill-button {
  cursor: pointer;
  margin-bottom: 20px;
  padding: 12px 16px;
  font-size: 16px;
  color: white;
  background-color: #D22B2B;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none;    /* Removes default styling on Firefox */
  appearance: none;         /* Removes default styling */
  font-family: 'Montserrat', sans-serif;
}

</style>
